import $ from 'jquery'

const config = {

	BASE: '/',
	siteLoaded : false,
	body: $('body'),
	menu: $('.menu'),
	loader: $('#loader'),
	view: $('#js-view'),
	delay: .7,
	width: window.innerWidth,
	height: window.innerHeight,
	sizes: [
		{ columns: 1, gutter: 10 },
		{ mq: '768px', columns: 2, gutter: 10 }
	]
}

export default config
