import $ from 'jquery'
import config from 'config'
import gsap from 'gsap'
import BezierEasing from 'bezier-easing'
import render from '../components/render'
import stage from '../components/stage'
import loader from '../components/loader'
import flickr from 'justified-layout'

class Project  {

	constructor(slug) {
		this.slug = slug
		this.ease = BezierEasing(.25,.1,.25,1)
	}

	pageIn(){
		this.init()
	}

	init() {

		const ready = this.ready.bind(this)
		loader.loadpage(this.slug).then(
			this.success.bind(this),
			this.failure.bind(this)
		)
	}

	success(response){
		this.page = response.find('.page')
		this.ready()
	}

	failure(error){
	}

	ready() {

		this.boxes = $('.box', this.page);

		this.renderQueue = this.renders.bind(this)
		stage.on("stage:scroll", this.renderQueue)
		this.resizeQueue = this.resizeQue.bind(this)
		stage.on("stage:resize", this.resizeQueue)

		this.startPage()
	}

	doLayout(tolerance){
		var elements = $('[data-ratio]').get()
	  var data = $('[data-ratio]').map(function() {
	    return $(this).data('ratio');
	  }).get();

	  var layoutGeometry = flickr(data, {containerWidth:1400, containerPadding:0, boxSpacing: 25, targetRowHeightTolerance: tolerance})
	  var boxes = layoutGeometry.boxes
	  $.each(boxes, (index, el) => {
	    var percent = (el.width/14).toFixed(2)

	    $(elements[index]).parent().css({width: percent + "%"})
	  })
	}


	startPage() {

		config.body.addClass( `is-project`)
		this.resizeQue(stage)

		TweenMax.to(this.page, 1.3, {
			alpha: 1,
			ease: Expo.easeInOut,
			onComplete: this.removeLoader,
			onCompleteScope: this

		})

	}

	removeLoader(){

	}

	resizeQue(e){

		this.renders(stage)

	}


	renders(e) {

		const hascover = e.scrolltop > 20
		config.body.toggleClass('has-cover', hascover)

	}

	pageOut(done){

		TweenMax.to($(this.page), 0, { opacity:0, ease: new Ease(BezierEasing(.42,0,.3,1)), onComplete: this.destroy, onCompleteScope: this, onCompleteParams: [done]})

	}

	destroy(done) {
		stage.removeListener("stage:scroll", this.renderQueue)
		stage.removeListener("stage:resize", this.resizeQueue)
		config.body.removeClass(`is-project`)
		done()
	}

}

module.exports = Project
