import $ from 'jquery'
import config from 'config'
import gsap from 'gsap'
import 'slick-carousel'
import BezierEasing from 'bezier-easing'
import render from '../components/render'
import stage from '../components/stage'
import loader from '../components/loader'

class Homepage  {

	constructor(slug) {
		this.slug = '/admin'
		this.ease = BezierEasing(.25,.1,.25,1)
	}

	pageIn(){
		this.init()
	}

	init() {

		const ready = this.ready.bind(this)
		loader.loadpage(this.slug).then(
			this.success.bind(this),
			this.failure.bind(this)
		)
	}

	success(response){
		this.page = response.find('.page')
		this.ready()
	}

	failure(error){
	}

	ready() {



		$('.fade').slick({
		  dots: false,
		  infinite: true,
			arrows: false,
		  speed: 500,
		  fade: true,
			autoplay: true,
		  cssEase: 'linear'
		});



		this.startPage()
	}


	startPage() {

		config.body.addClass( `is-home`)
		this.resizeQue(stage)

		TweenMax.to(this.page, 1.3, {
			alpha: 1,
			ease: Expo.easeInOut,
			onComplete: this.removeLoader,
			onCompleteScope: this

		})

	}

	removeLoader(){

	}

	resizeQue(e){

		if(!e)
			e = stage



		this.renders(stage)

	}


	renders(e) {




	}

	pageOut(done){

		TweenMax.to($(this.page), .7, {opacity:0, ease: new Ease(BezierEasing(.42,0,.3,1)), onComplete: this.destroy, onCompleteScope: this, onCompleteParams: [done]})
	}

	destroy(done) {

		config.body.removeClass(`is-home`)
		done()
	}

}

module.exports = Homepage
