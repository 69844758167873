import $ from 'jquery'
import config from 'config'
import cache from 'cache'
import gsap from 'gsap'
import crossroads from 'crossroads'
import stage from './components/stage'
import BezierEasing from 'bezier-easing'

import Homepage from './sections/home'
import About from './sections/about'
import Project from './sections/project'
import Projects from './sections/projects'
import Contact from './sections/contact'

import './ScrollToPlugin'


class Router {

	constructor() {
		this.currentPage = null
		this.prevPage = null
		this.animation = 0
		this.initialized = false
	}

	init() {
		const root = ''

		const home = crossroads.addRoute(root + '/')
		const projects = crossroads.addRoute(root + '/projects')
		const category = crossroads.addRoute(root + '/category/{catgory}')
		const project = crossroads.addRoute(root + '/projects/{project}')
		const about = crossroads.addRoute(root + '/about')
		const contact = crossroads.addRoute(root + '/contact')


		home.matched.add(this.homeRoute, this)
		projects.matched.add(this.projectsRoute, this)
		category.matched.add(this.projectsRoute, this)
	  project.matched.add(this.projectRoute, this)
		about.matched.add(this.aboutRoute, this)
	  contact.matched.add(this.contactRoute, this)

		this.addEvents()

	}

	parseRoute(route, data){
		const root = ''
		//console.log(route, data);
		this.section = route == root + '' ? 'home' : route
		crossroads.parse(route)

	}

	crossroadParse(route){
		crossroads.parse(route)
	}

	homeRoute(){

		if(!cache[this.section] ){
	    cache[this.section] = new Homepage(this.section)
	  }
		this.transitionRoute()
	}

	projectsRoute(){
		if(!cache[this.section] ){
	    cache[this.section] = new Projects(this.section)
	  }
		this.transitionRoute()
	}

	projectRoute(){
		if(!cache[this.section] ){
	    cache[this.section] = new Project(this.section)
	  }
		this.transitionRoute()
	}

	aboutRoute(){
		if(!cache[this.section] ){
	    cache[this.section] = new About(this.section)
	  }
		this.transitionRoute()
	}

	contactRoute(){
		if(!cache[this.section] ){
	    cache[this.section] = new Contact(this.section)
	  }
		this.transitionRoute()
	}



	transitionRoute(){

		config.menu.toggleClass('negative', this.section == 'home')
		config.body.removeClass('has-nav')
		$('.hamburger').removeClass('is-active')

		this.prevPage = this.currentPage
	  this.currentPage = cache[this.section]

		this.prevPage && this.prevPage.pageOut(this.transition.bind(this))
		!this.prevPage && this.currentPage.pageIn()
	}

	transition(){
		//$(window).scrollTop(0)
		this.currentPage.pageIn()
	}

	addEvents(){

		//console.log('router init');

    $('.menu a').on('click', (e)=>{

			const target = $(e.currentTarget)
			if (target.hasClass('no-route') || target[0].hasAttribute('target') && target[0].getAttribute('target') == '_blank') return

			e.preventDefault()
      const url = target.attr('href')
			//console.log(url);
      config.body.addClass('start')
			History.pushState(null, null, url );

    })

		$('#js-view').on('click', 'a', (e)=>{

			const target = $(e.currentTarget)
			if (target.hasClass('no-route') || target[0].hasAttribute('target') && target[0].getAttribute('target') == '_blank') return

			e.preventDefault()
      const url = target.attr('href')
			//console.log(url);
      config.body.addClass('start')
			History.pushState(null, null, url );

    })


		$('.hamburger').on('click', (e)=>{
			config.body.toggleClass('has-nav')
			$('.hamburger').toggleClass('is-active')
		})


		$('#js-view').on('click', '.back', (e)=>{

			e.preventDefault()
			TweenMax.to(window, 1, {scrollTo:0, ease: Power4.easeOut})
		})


	}


	stateChange(){
		var State = History.getState();
		var url = State.url
		var relativeUrl = History.getShortUrl(url).replace(/\/$/, "");
		var s = relativeUrl;
		var m = s.indexOf('?')
		var n = m != -1 ?  m : s.length;
		relativeUrl = s.substring(0, n);
	  this.parseRoute(relativeUrl, State.data)

	}
}

module.exports = Router
