import $ from 'jquery'
import config from 'config'
import templates from 'templates'

var Promise = require('es6-promise').Promise;

class Loader {

	constructor() {
		this.preview =  config.preview ? '?preview=1' : ''
	}
	init(){
	}

	loadpage(url){

		const promise =  new Promise((resolve, reject) =>  {

		if(config.siteLoaded){

			config.view.empty()

			if(!templates[url]){

				$.get({
					url: url + '/',
					dataType: 'html',
					success: (object) => {
						const html = done(object)
						templates[url] = html.page
						config.view.html(html.page)
						resolve(config.view);
					}
				})
			}
			else{
				setTimeout(() => {
					config.view.html(templates[url])
					resolve(config.view);
				}, 1)
			}
		}
		else {
			config.siteLoaded = true
			setTimeout(() => {
					templates[url] = config.view.html()
					resolve(config.view);
			}, 1)
		}
		})

		return promise
	}


}

const done = (data) => {
	//console.log(data)
	const $data = $(documentHtml(data))
	const $dataBody = $data.find('.document-body:first')
	const $dataBoard = $dataBody.find("#js-view").filter(':first')
	const boardHtml = $dataBoard.html()

	return {page:boardHtml}
}

const documentHtml = (html) => {
	const result = String(html)
		.replace(/<\!DOCTYPE[^>]*>/i, '')
		.replace(/<(html|head|body|title|meta|script)([\s\>])/gi,'<div class="document-$1"$2')
		.replace(/<\/(html|head|body|title|meta|script)\>/gi,'</div>')
	;
	// Return
	return $.trim(result);
}

const instance = new Loader()
module.exports = instance
