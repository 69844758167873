import $ from 'jquery'
import config from 'config'
import gsap from 'gsap'
import BezierEasing from 'bezier-easing'
import render from '../components/render'
import stage from '../components/stage'
import loader from '../components/loader'

class Contact  {

	constructor(slug) {
		this.slug = slug
		this.ease = BezierEasing(.25,.1,.25,1)
	}

	pageIn(){
		this.init()
	}

	init() {

		const ready = this.ready.bind(this)
		loader.loadpage(this.slug).then(
			this.success.bind(this),
			this.failure.bind(this)
		)
	}

	success(response){
		this.page = response.find('.page')
		this.ready()
	}

	failure(error){
	}

	ready() {


		this.initMap()
		this.startPage()
	}

	initMap() {
		var uluru = {lat: 46.777288, lng: 23.583986};
		var mapOptions = {
			center: uluru,
			zoom: 16,
			minZoom:14,
			maxZoom:20,
			zoomControl: true,
			zoomControlOptions:{
					style: google.maps.ZoomControlStyle.LARGE,
					position: google.maps.ControlPosition.TOP_RIGHT
				},
			mapTypeControl: false,
			streetViewControl: false,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			scrollwheel: false,
			draggable: true,
			styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
		}

		this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
		this.marker = new google.maps.Marker({
			position: uluru,
			map: this.map,
			icon: '/images/pin.png'
		});
	}


	startPage() {

		config.body.addClass( `is-contact`)


		TweenMax.to(this.page, 1.3, {
			alpha: 1,
			ease: Expo.easeInOut,
			onComplete: this.removeLoader,
			onCompleteScope: this

		})

	}

	removeLoader(){

	}



	pageOut(done){

		TweenMax.to($(this.page), .7, {y: -stage.halfh, opacity:0, ease: new Ease(BezierEasing(.42,0,.3,1)), onComplete: this.destroy, onCompleteScope: this, onCompleteParams: [done]})

	}

	destroy(done) {
		this.marker = null
		this.map = null
		
		config.body.removeClass(`is-contact`)
		done()
	}

}

module.exports = Contact
