import $ from 'jquery'
import config from 'config'
import gsap from 'gsap'
import BezierEasing from 'bezier-easing'
import render from '../components/render'
import stage from '../components/stage'
import loader from '../components/loader'

class About  {

	constructor(slug) {
		this.slug = slug
		this.ease = BezierEasing(.25,.1,.25,1)
	}

	pageIn(){
		this.init()
	}

	init() {

		const ready = this.ready.bind(this)
		loader.loadpage(this.slug).then(
			this.success.bind(this),
			this.failure.bind(this)
		)
	}

	success(response){
		this.page = response.find('.page')
		this.ready()
	}

	failure(error){
	}

	ready() {

		this.startPage()
	}


	startPage() {

		config.body.addClass( `is-about`)
		//this.resizeQue(stage)

		TweenMax.to(this.page, 1.3, {
			alpha: 1,
			ease: Expo.easeInOut,
			onComplete: this.removeLoader,
			onCompleteScope: this

		})

	}

	removeLoader(){

	}



	pageOut(done){

		TweenMax.to($(this.page), .7, {y: -stage.halfh, opacity:0, ease: new Ease(BezierEasing(.42,0,.3,1)), onComplete: this.destroy, onCompleteScope: this, onCompleteParams: [done]})

	}

	destroy(done) {
		config.body.removeClass(`is-about`)
		done()
	}

}

module.exports = About
