import $ from 'jquery'
import config from 'config'
//import sniffer from 'sniffer'
import 'history'
import Router from './router'

window.lazySizesConfig = window.lazySizesConfig || {};
lazySizesConfig.lazyClass = 'lazy';
lazySizesConfig.loadingClass = 'lazyloading';
lazySizesConfig.loadedClass = 'lazyloaded';

import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import 'lazysizes/plugins/object-fit/ls.object-fit'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/respimg/ls.respimg'
import 'lazysizes'

class App {

  constructor() {
    this.init()
  }

  init() {

    //console.log('app init');
     this.router = new Router()
  //   config.infos = sniffer.getInfos()
  // //  config.body.removeClass('is-loading initializing')
  //   this.addEvents()
     this.router.init()
     History.Adapter.bind(window,'statechange', this.router.stateChange.bind(this.router))
     History.Adapter.trigger(window, 'statechange')
     config.siteLoaded = true

  //var data = $('[data-ratio]').data('ratio')


  }

  addEvents() {

    //$('.footer__bg').html($('#bg-images').html())
  }
}

module.exports = App
